// @flow
import React from 'react';
import { connect } from 'react-redux';
import { logout, login, AuthStore } from '@an/nova-frontend-auth';
import type { User as UserDefinition } from '@an/nova-frontend-auth';
import { version } from '../../../package.json';
import Config from '../../config';

type Props = {
  user: UserDefinition,
};

type State = {
  apiVersion?: string,
};

class UserProfile extends React.Component<Props, State> {
  state = { apiVersion: undefined };

  async componentDidMount() {
    const res = await fetch(`${Config.apiServiceStatusUrl}`);
    const data = await res.json();

    if (data) {
      this.setApiVersion(data);
    }
  }

  render() {
    const { user } = this.props;
    const { apiVersion } = this.state;

    if (user) {
      return (
        <div className="nav-block user-profile">
          <div>App v{version}</div>
          {apiVersion && <div>Api v{apiVersion}</div>}
          <div>
            {user.givenName} {user.familyName}
          </div>
          <div>
            <a href="/logout" onClick={this.onLogout}>
              Logout
            </a>
          </div>
        </div>
      );
    }
    return (
      <div className="nav-block user-profile">
        <div>Please </div>
        <a href="/" onClick={this.onLogin}>
          Login
        </a>
      </div>
    );
  }

  setApiVersion = data => {
    this.setState({ apiVersion: data.Version });
  };

  onLogin = (e: Event) => {
    e.preventDefault();
    login();
  };

  onLogout = (e: Event) => {
    e.preventDefault();
    logout();
  };
}

const mapStateToProps = state => ({
  user: AuthStore.getAuthenticatedUser(state),
});

export default connect(mapStateToProps)(UserProfile);
