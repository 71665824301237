// @flow
// eslint-disable-next-line no-underscore-dangle
const env = window._env_ || process.env;
const config = {
  adClientId:
    env.REACT_APP_AD_CLIENT_ID || '367bf05b-c392-4b15-960b-24778a3c2a7a',
  apiServiceStatusUrl:
    env.REACT_APP_API_SERVICE_STATUS ||
    'https://dev-nova-admin-api.azurewebsites.net/service-status',
  apiBaseUrl:
    env.REACT_APP_API_BASE ||
    'https://dev-nova-admin-api.azurewebsites.net/api',
  clientBaseUrl: env.REACT_APP_CLIENT_BASE_URL || 'http://localhost:3004',
  localhostUrl: 'http://localhost:5253',
  devApiUrl: 'https://dev-nova-app-admin-api.azurewebsites.net/api',
  devApiUrlNew: 'https://dev-nova-admin-api.azurewebsites.net/api',
  devApiADAppUrl: 'https://ukmarrow.org/dev-nova-admin-api',
  uatApiUrl: 'https://uat-nova-app-admin-api.azurewebsites.net/api',
  uatApiUrlNew: 'https://uat-nova-admin-api.azurewebsites.net/api',
  uatApiADAppUrl: 'https://ukmarrow.org/uat-nova-admin-api',
  liveApiUrl: 'https://live-nova-app-admin-api.azurewebsites.net/api',
  liveApiUrlNew: 'https://live-nova-admin-api.azurewebsites.net/api',
  liveApiADAppUrl: 'https://ukmarrow.org/live-nova-admin-api',
};
export default config;
