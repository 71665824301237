// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthenticationScreens, LoggedInRoute } from '@an/nova-frontend-auth';
import Header from './core/component/Header';
import Csvs from './csvs/component/Csvs';
import Uploader from './upload/component/uploader';
import Home from './Home';

const AppRoutes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/csvs" component={Csvs} />
    <Route path="/upload" component={Uploader} />
  </Switch>
);

export default () => (
  <div className="App">
    <Header />
    <section className="section group">
      <div className="col span_12_of_12">
        <Switch>
          <Route path="/auth" component={AuthenticationScreens} />
          <LoggedInRoute component={AppRoutes} />
        </Switch>
      </div>
    </section>
  </div>
);
