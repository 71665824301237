// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import { uploadCsvFile } from '../redux/uploadActions';

import type { CategoryUploadResponse } from '../type';

registerPlugin(FilePondPluginFileValidateSize);

type Props = {
  uploadCsvFile: Object => Promise<*>,
};

class Uploader extends React.Component<Props> {
  render() {
    return (
      <div className="uploader" style={{ textAlign: 'center' }}>
        <p>CSV Uploader</p>
        <FilePond
          maxFileSize="2MB"
          labelTapToCancel=""
          labelTapToUndo=""
          labelFileProcessing="Uploading..."
          dropOnPage
          server={{
            process: (fieldName, file, metadata, load, error) => {
              this.upload(file, data => {
                // $FlowFixMe
                if (data && data.response && data.response.ok) {
                  load();
                } else {
                  error();
                }
              });
            },
          }}
        />
      </div>
    );
  }

  upload = async (
    csvFile: File,
    evaluate: (data: CategoryUploadResponse) => void
  ) => {
    Papa.parse(csvFile, {
      skipEmptyLines: true,
      header: true,
      complete: async results => {
        if (results.data === undefined || results.data.length === 0) {
          // $FlowFixMe
          return evaluate();
        }
        const data = await this.props.uploadCsvFile(results.data);
        return evaluate(data);
      },
    });
  };
}

const mapDispatchToProps = dispatch => ({
  uploadCsvFile: bindActionCreators(uploadCsvFile, dispatch),
});

export default connect(null, mapDispatchToProps)(Uploader);
