// @flow
import React from 'react';
import classNames from 'classnames';

export type ButtonDefinition = {
  loading?: boolean,
  onClick?: (e: Event) => any,
  primary?: boolean,
  secondary?: boolean,
  small?: boolean,
  tertiary?: boolean,
  text?: string,
  type?: 'button' | 'submit',
};

const Button = ({
  onClick,
  loading,
  primary,
  secondary,
  type,
  tertiary,
  small,
  text,
}: ButtonDefinition) => {
  const buttonClass = classNames('btn', 'btn--inline', {
    'btn--primary': primary,
    'btn--secondary': secondary,
    'btn--tertiary': tertiary,
    'btn--small': small,
  });
  return (
    <button type={type} className={buttonClass} onClick={onClick}>
      {loading && <div className="loader loader--btn">&nbsp;</div>}
      {text}
    </button>
  );
};
Button.defaultProps = {
  loading: false,
  onClick: undefined,
  primary: true,
  secondary: false,
  small: false,
  tertiary: false,
  text: '',
  type: 'button',
};

export default Button;
