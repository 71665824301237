// @flow
import { get } from '@an/nova-frontend-rest-client';
import urlBuilder from '../../core/helpers/urlBuilder';
import Config from '../../config';

export const getFileTypes = () =>
  // $FlowFixMe
  get(`${Config.apiBaseUrl}/alms/exports`, 'csv/getFileTypes', {});

export const getFilesByType = (fileType: string) =>
  // $FlowFixMe
  get(`${Config.apiBaseUrl}/alms/exports/${fileType}`, 'csv/getFilesByType', {
    fileType,
  });

export const downloadFile = (fileType: string, uri: string) => {
  const csvDownloadUrl = urlBuilder(`/alms/exports/:fileType/download`, {
    fileType,
    uri,
  });

  window.location = csvDownloadUrl;

  return undefined;
};
