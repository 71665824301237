// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/an-logo-horizontal.svg';

export default () => (
  <Link to="/">
    <img
      src={logo}
      alt="Anthony Nolan | Saving the lives of people with blood cancer"
    />
  </Link>
);
