// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AdalAuthModule, initialise } from '@an/nova-frontend-auth';
import { setAuthenticationModule } from '@an/nova-frontend-rest-client';
import config from './config';
import configureStore from './store/configureStore';
import './assets/styles/App.scss'; // App specific styles
import App from './App';
import { unregister } from './registerServiceWorker';

const store = configureStore();

initialise(config.adClientId, 'ukmarrow.org', config.clientBaseUrl, {
  [config.localhostUrl]: config.uatApiADAppUrl,
  [config.devApiUrl]: config.devApiADAppUrl,
  [config.devApiUrlNew]: config.devApiADAppUrl,
  [config.uatApiUrl]: config.uatApiADAppUrl,
  [config.uatApiUrlNew]: config.uatApiADAppUrl,
  [config.liveApiUrl]: config.liveApiADAppUrl,
  [config.liveApiUrlNew]: config.liveApiADAppUrl,
});

setAuthenticationModule(AdalAuthModule);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  // $FlowExpectedError
  document.getElementById('root')
);
unregister();
