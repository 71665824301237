// @flow
import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import UserProfile from './UserProfile';

export default () => (
  <header>
    <div className="nav section group">
      <Menu />
      <UserProfile />
    </div>
    <div className="section group">
      <div className="col span_5_of_12">
        <Logo />
      </div>
      <div className="col span_7_of_12">
        <h1>AN ADMIN FRONTEND</h1>
      </div>
    </div>
  </header>
);
