// @flow
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Error } from '../../core';
import {
  getFileTypes,
  getFilesByType,
  downloadFile,
} from '../redux/csvActions';

import type { FilesByTypeDefinition, FileTypeDefinition } from '../type';
import type { ApiError } from '../../core/type/index';

type Props = {
  getFileTypes: () => Promise<*>,
  getFilesByType: (string | void) => Promise<*>,
};

type State = {
  error?: ApiError,
  currentFileType?: string,
  fileTypes?: FileTypeDefinition[],
  filesByType?: FilesByTypeDefinition,
  loading: boolean,
};

class Csvs extends React.Component<Props, State> {
  state = {
    currentFileType: undefined,
    filesByType: undefined,
    fileTypes: undefined,
    loading: false,
    error: undefined,
  };

  async componentDidMount() {
    this.setState({ loading: true });

    const data = await this.props.getFileTypes();

    if (data && data.payload) {
      this.setState({
        fileTypes: data.payload,
        currentFileType: data.payload[0].Key,
        loading: false,
      });
    } else {
      this.setState({ error: data.payload });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.currentFileType !== this.state.currentFileType) {
      if (this.state.currentFileType) {
        this.setState({ loading: true });

        const data = await this.props.getFilesByType(
          this.state.currentFileType
        );

        if (data && data.response && data.response.ok) {
          this.setState({
            filesByType: data.payload,
            loading: false,
          });
        } else {
          this.setState({ error: data.payload });
        }
      }
    }
  }

  render() {
    const { error, fileTypes, filesByType, loading } = this.state;

    const currentFileType =
      this.state.currentFileType || (fileTypes && fileTypes[0].Key);

    return (
      <div>
        <ul className="nav-tabs">
          {fileTypes &&
            fileTypes.map(type => (
              <li key={type.Key}>
                <button
                  href=""
                  className={currentFileType === type.Key ? 'active' : ''}
                  onClick={e => this.onTabChange(e, type.Key)}
                >
                  {type.Name}
                </button>
              </li>
            ))}
        </ul>
        <div>
          <table className="csv-table">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Created On</th>
              </tr>
            </thead>
            <tbody>
              {filesByType &&
                filesByType.Data.map(data =>
                  data.Contents.map(file => (
                    <tr key={file.Uri}>
                      <td>{file.Filename}</td>
                      <td>{moment(data.CreationDate).format('DD/MM/YYYY')}</td>
                      <td className="csv-download--btn">
                        <Button
                          text="Download"
                          small
                          value={file.Uri}
                          onClick={e => this.downloadFile(e, file.Uri)}
                        />
                      </td>
                    </tr>
                  ))
                )}
            </tbody>
          </table>
          {loading && <div className="loader">&nbsp;</div>}
          {error && <Error error={error} />}
        </div>
      </div>
    );
  }

  onTabChange = (e: Event, name: string) => {
    e.preventDefault();
    this.setState({
      currentFileType: name,
    });
  };

  downloadFile = (e: Event, uri: string) => {
    const { currentFileType } = this.state;

    if (currentFileType) {
      downloadFile(currentFileType, uri);
    }
  };
}

const mapDispatchToProps = dispatch => ({
  getFileTypes: bindActionCreators(getFileTypes, dispatch),
  getFilesByType: bindActionCreators(getFilesByType, dispatch),
});

export default connect(null, mapDispatchToProps)(Csvs);
