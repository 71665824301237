// @flow
import { getAuthToken } from '@an/nova-frontend-auth';
import UrlAssembler from 'url-assembler';
import Config from '../../config';

const urlBuilder = (pathName: string, params: Object): string => {
  const resourceUrl = UrlAssembler(Config.apiBaseUrl)
    .template(pathName)
    .param(params)
    .toString();

  const token = getAuthToken(resourceUrl);

  return UrlAssembler(resourceUrl)
    .query({ token })
    .toString();
};

export default urlBuilder;
