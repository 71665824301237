// @flow
import * as React from 'react';

import type { ApiError } from '../type';

type Props = {
  error?: ApiError,
};

const Error = ({ error }: Props) => {
  if (!error) return null;
  const messages = [];
  if (error.error) {
    messages.push(error.error);
  } else if (error.Error) {
    messages.push(error.Error);
  } else if (error.globalErrors && error.globalErrors.length > 0) {
    messages.push(error.globalErrors[0]);
  } else if (error.fieldErrors) {
    Object.keys(error.fieldErrors).forEach(field => {
      if (error.fieldErrors && error.fieldErrors[field])
        messages.push(error.fieldErrors[field].errors[0]);
    });
  } else {
    messages.push('Unknown error format, please contact IT');
  }

  return messages.map<React$Element<any>>(message => (
    <div key={message} className="error-message">
      {message}
    </div>
  ));
};

Error.defaultProps = {
  error: undefined,
};

export default Error;
