// @flow
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import rootReducer from '../core/redux/rootReducer';

const middleware = [];
middleware.push(thunkMiddleware);

if (process.env.NODE_ENV === 'development') {
  middleware.push(immutableCheckMiddleWare());
  middleware.push(createLogger());
}

export default function configureStore(preloadedState?: Object) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  // $FlowExpectedError
  if (module.hot) {
    // $FlowExpectedError
    module.hot.accept('../core/redux/rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextReducer = require('../core/redux/rootReducer');
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
