// @flow
import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <div className="nav-block menu">
    <div>
      <Link to="/">Home</Link>
    </div>
    |
    <div>
      <Link to="/csvs">CSVs</Link>
    </div>
    |
    <div>
      <Link to="/upload">CSV Uploader</Link>
    </div>
  </div>
);
